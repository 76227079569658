<template>
  <!--
    The view for the cloudStorage-component.
  -->
  <div
    class="cloudStorage"
  >
    <Portlet
      :title="$t('cloudStorageView.cloudStorageDivider')"
      :sub-title="$t('cloudStorageView.downloadsFromAmazon')"
      icon="folder"
      class="cloudStorage"
    >
      <CloudStorageList :path="path" />
    </Portlet>
    <ThemeStaticResourceUploader />
  </div>
</template>

<script>
export default {
  name: "CloudStorage",
  components: {
    CloudStorageList: () => import('@/components/CloudStorageList.vue'),
    ThemeStaticResourceUploader: () => import('@/components/TerminalTheme/ThemeStaticResourceUploader.vue')
  },
  props: {
    path: {
      type: String,
      required: false,
      default () {
        return '';
      }
    }
  },
  metaInfo () {
    return {
      title: this.$t('cloudStorageView.cloudStorage')
    }
  }
}
</script>